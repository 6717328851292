@use "@angular/material" as mat;

// -------------- Boostrap import --------------

@import "../node_modules/bootstrap/scss/functions";

// variables override
$font-size-base: 0.88rem;
$link-decoration: none;
$link-hover-decoration: underline;
/* Focused elements configuration */
$focus-ring-opacity: 0.25;
$focus-ring-color: rgba(100, 100, 100, $focus-ring-opacity);
$component-active-bg: #646464;

@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/transitions";

@import "../node_modules/bootstrap/scss/utilities/api";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/forms";

// -------------- Application Themes --------------
@import "./themes.scss";

// -------------- SVG icons --------------
@import "./icons.scss";

// -------------- Global styles --------------

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: var(--fof-border-color);
  border-radius: 8px;
}

.pby-bot-app {
  background: none;
  z-index: 10;
  position: relative;
  -webkit-font-smoothing: antialiased; // safari mac nicer
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--fof-font-family);
}

.btn:focus-visible {
  border-color: #{$focus-ring-color} !important;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem #{$component-active-bg}40;
}

/** A span that behaves like a link */
span[role="button"] {
  color: rgb(var(--bs-link-color-rgb)) !important;
  text-decoration: $link-decoration !important;
  &:hover {
    text-decoration: $link-hover-decoration !important;
  }
}

// -------------- Bootstrap styles override --------------

/** Enrich Bootstrap rounded border utilities */
.rounded-top-start-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}
.rounded-top-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}
.rounded-bottom-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}
